import { Action, createReducer, on } from '@ngrx/store';
import firebase from 'firebase/compat/app';
import { jwtDecode } from 'jwt-decode';
import { AuthActions } from '.';
import { AppConfig } from '../shared/models/app-config.model';
import { Area } from '../shared/models/area.model';
import { BrowserStorageItem } from '../shared/models/browser-storage.model';
import { ReportedErrorEvent } from '../shared/models/result.model';

export interface AuthState {
  idToken: string;
  claims: ClaimSet;
  userInfo: firebase.UserInfo;
  initialized: boolean;
  areas: Area[];
  userAreaIds: string[];
  browserStorage: BrowserStorageItem[];
  errorEvent: ReportedErrorEvent;
  activeUrl?: string;
  isSideNavOpened: boolean;
  isHandset: boolean;
  appConfig: AppConfig;
}

export interface ClaimSet {
  auth_time: number;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  iss: string;
  roles?: Role[];
  sub: string;
  user_id: string;
  isHandset: boolean;
  isSideNavOpened: boolean;
}

export type Role = 'operator' | 'b2baccountmanager' | 'supportagent' | 'console-web.finance_manager';

export const authReducer = createReducer(
  getInitialState(),
  on(AuthActions.idTokenChanged, (state, { idToken }) => ({
    ...state,
    idToken: idToken,
    initialized: true,
    claims: idToken ? (jwtDecode(idToken) as ClaimSet) : null,
  })),
  on(AuthActions.userInfoChanged, (state, { userInfo }) => ({
    ...state,
    userInfo: userInfo,
  })),
  on(AuthActions.userAreasFetched, (state, { userAreaIds }) => ({
    ...state,
    userAreaIds: userAreaIds,
  })),
  on(AuthActions.browserStorageItemFetched, (state, { item }) => ({
    ...state,
    browserStorage: [...state.browserStorage, item],
  })),
  on(AuthActions.errorEventFetched, (state, action) => ({
    ...state,
    errorEvent: action.state,
  })),
  on(AuthActions.activeUrlChanged, (state, { url }) => ({
    ...state,
    activeUrl: url,
    isSideNavOpened: !state.isHandset,
  })),
  on(AuthActions.breakpointObserverChanged, (state, { isHandset }) => ({
    ...state,
    isHandset: isHandset,
    isSideNavOpened: !isHandset,
  })),
  on(AuthActions.sideNavToggled, state => ({
    ...state,
    isSideNavOpened: !state.isSideNavOpened,
  })),
  on(AuthActions.areasFetched, (state, { areas }) => ({
    ...state,
    areas: areas,
  })),
  on(AuthActions.appConfigChanged, (state, { appConfig }) => ({
    ...state,
    appConfig: appConfig,
  })),
);

export function reducer(state: AuthState, action: Action) {
  return authReducer(state, action);
}

export function getInitialState(): AuthState {
  return {
    idToken: null,
    claims: null,
    userInfo: null,
    initialized: false,
    areas: [],
    userAreaIds: [],
    browserStorage: [],
    errorEvent: null,
    activeUrl: null,
    isHandset: false,
    isSideNavOpened: true,
    appConfig: undefined,
  };
}
